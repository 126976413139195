@tailwind base;
@tailwind components;
@tailwind utilities;

p,
h1,
h2,
h3,
label {
  @apply text-primary-900;
}

input[type='text']:not([id*='react-select']) {
  @apply h-[50px] rounded-[15px] border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors focus:border-primary-300 focus:border-opacity-100;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
#scrollable-labels::-webkit-scrollbar {
  width: 14px;
}

/* Track */
#scrollable-labels::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
#scrollable-labels::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

body {
  color: #2f2e41;
}

/* Handle on hover */
#scrollable-labels::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#scrollable-labels::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

#scrollable-labels::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.PhoneInputInput {
  @apply h-[50px] rounded-[15px] border-2 border-secondary-200 border-opacity-50 bg-secondary-200 bg-opacity-50 font-medium text-primary-900 transition-colors placeholder:text-opacity-50 focus:border-primary-300;
}

:focus.PhoneInputInput {
  outline: none;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.invalid > .PhoneInputInput {
  @apply border-error;
}

.PhoneInput {
  width: 100%;
}

:root {
  --PhoneInput-color--focus: #01afa5;
}

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  margin-right: -50vw;
}
#root {
  white-space: pre-line;
}
